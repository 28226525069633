import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import organImage from '../../images/infovis/organ_donation.jpg'
import { StyledContainer } from '../layout'
import {Header2WithLeftBoarder, MainText1} from './infovis_style'

class ProblemStatementInfoVis extends React.Component{

  render(){

    return(

      <StyledContainer fluid={true}>

        <Row style={{paddingBottom:`32px`}}>
          <Col md={3}/>
          <Col md={4}>
            <Header2WithLeftBoarder> Problem Statement </Header2WithLeftBoarder>
          </Col>
        </Row>

        <Row>
          <Col md={3}/>
          <Col md={4}>
            <MainText1>
              Organ transplantation is the process of replacing one patient’s failing organ with a healthy
              organ from an donor. It is one of most effective medical technology to save patients. However,
              due to various limitations in human biology, technology, ethics, and regulations, getting an
              kidney, is really hard. We realized that many eligible patients are waiting on a extremely
              long waiting list, especially kidney. Each transplantation is not only the matter of donors and
              patients; each success is an effort of the society involving various departments and people. Thus,
              we want to present the data about the situation of the kidney transplantation to the public, as
              well as those patients, in U.S., to call for attention on this issue.
            </MainText1>
          </Col>

          <Col md={2}>
            <img src={organImage} className="img-fluid" style={{paddingLeft:`10px`}} alt="gift"/>
          </Col>
        </Row>


      </StyledContainer>

    )
  }
}

export default ProblemStatementInfoVis