import {Row, Col, Container, Button, Modal, ModalHeader, ModalBody} from 'reactstrap'
import styled from 'styled-components'
import React from 'react'
import {Header2, Header3, getColOf3, getSeperatorWithHeight, HeaderForSubtitle, Caption} from '../text_style'
import { StyledContainer } from '../layout'
import {
  Header3WithLeftBorder,
  Header2SubtitleWithLeftBorder,
  Header4WithLeftBorder,
  MainText1Bold,
  MainText1SemiBold,
  MainText1,
  accentColor,
} from './hci_foundation_style'
import design2wireframe from '../../images/hci_foundation/Wireframe-design-alternatives-two.png'
import storyboard2Image from '../../images/hci_foundation/storyboard-2.jpg'
import feedbackImage from '../../images/hci_foundation/Design-alternative-2-feedback.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ZoomableImageWithModal } from './hci_common_component'


let MainTextSizeChanged = styled(MainText1)`
    line-height: 26px;
`


class DesignAlternative2 extends React.Component{


  constructor(props){
    super(props);
    this.state= {feedbackModalOpen : false,
      wireframeModalOpen: false}
  }

  feedbackModalToggle = ()=>{
    this.setState({feedbackModalOpen: !this.state.feedbackModalOpen,
    })
  }

  wireframeModalToggle = ()=>{
    this.setState({wireframeModalOpen: !this.state.wireframeModalOpen,
    })
  }



  render(){

    return(

     <StyledContainer fluid={true}>
       <Row style={{marginTop: `12px`}}>
         {getColOf3()}

         <Col md={6}>
           <Header3WithLeftBorder>
             Design Alternative 2
           </Header3WithLeftBorder>
           <Header2SubtitleWithLeftBorder>
             Social Network
           </Header2SubtitleWithLeftBorder>

           {getSeperatorWithHeight(28)}

           <MainText1>
             This design enables users to consult mutual friends about gift recipients’ gift preference in a social
             forum. When making the decision, the gift buyers can summon those individuals’ to rate the ideas.
           </MainText1>

         </Col>
       </Row>

       {getSeperatorWithHeight(28)}

       <Row>
         {getColOf3()}
         <Col md={3} >

           <Row className={"wireframe"} style={{marginLeft:`0px`}}>
           <Header4WithLeftBorder>
             Wireframe
           </Header4WithLeftBorder>
           {/*<div style={{height:`200px`}}>*/}

           {/*<div className={"button"} onClick={this.wireframeModalToggle}>  <FontAwesomeIcon icon="search-plus" size={"sm"}/> </div>*/}

           {/*<Container>*/}
             {/*/!*<ZoomableImage style={{paddingRight: `5%`}} src={userJourneyMap} className={"img-fluid"} alt="User Journey Map" onClick={this.toggle}/>*!/*/}
             {/*<Modal isOpen={this.state.wireframeModalOpen} size='lg'>*/}
               {/*<ModalHeader toggle={this.wireframeModalToggle}>Design Alternative 2 Feedback</ModalHeader>*/}
               {/*<ModalBody>*/}
                 {/*<Container>*/}
                   {/*<img src={design2wireframe} className={"img"} alt="User Journey Map"/>*/}
                 {/*</Container>*/}
               {/*</ModalBody>*/}

             {/*</Modal>*/}
           {/*</Container>*/}

           </Row>
           {/*</div>*/}

           <ZoomableImageWithModal image ={design2wireframe} header={"Design Alternative 2 Wireframe"}/>
         </Col>

         <Col md={3}>
           <Header4WithLeftBorder>
             Rationale
           </Header4WithLeftBorder>

           {getSeperatorWithHeight(16)}

           <MainTextSizeChanged >
             <ol>
               <li>
                 User suggested consulting their friends or recipients’ is helpful
               </li>
               <li>Different friends of the recipients know different aspects of the recipients </li>
               <li>More people can generate more ideas and recommendations on gifts.  </li>

             </ol>
           </MainTextSizeChanged>

           {getSeperatorWithHeight(28)}
         </Col>


       </Row>

       <Row>
         {getColOf3()}
         <Col md={6}>
          <Row className={"feedback"} style={{marginLeft:`0px`}}>
           <Header4WithLeftBorder>
             Feedback
           </Header4WithLeftBorder>
            <div className={"button"}   style={{marginLeft: `auto`, marginRight: `0px`, fontSize: `13px`, borderRadius:"3px",
              padding:"0px 4px 0px 4px", borderColor:`${accentColor}`}} onClick={this.feedbackModalToggle}>  <FontAwesomeIcon icon="search-plus" size={"sm"}/> More detail </div>

            <Container>
              {/*<ZoomableImage style={{paddingRight: `5%`}} src={userJourneyMap} className={"img-fluid"} alt="User Journey Map" onClick={this.toggle}/>*/}
              <Modal isOpen={this.state.feedbackModalOpen} size='lg'>
                <ModalHeader toggle={this.feedbackModalToggle}>Design Alternative 1 Feedback</ModalHeader>
                <ModalBody>
                  <Container>
                    <img src={feedbackImage} className={"img"} alt="User Journey Map"/>
                  </Container>
                </ModalBody>

              </Modal>
            </Container>
          </Row>
         </Col>

       </Row>
       {getSeperatorWithHeight(16)}

       <Row>
         {getColOf3()}
         <Col md={3}>

           <MainTextSizeChanged>
             Pros:
             <ol>
               <li>
                 the element of <MainText1SemiBold> social support </MainText1SemiBold> and extra ideas
               </li>

             </ol>
           </MainTextSizeChanged>
         </Col>

         <Col md={3}>

           <MainTextSizeChanged>
             Cons:

             <ol>
               <li>
                 high time uncertainty
               </li>
               <li>
                 peer pressure
               </li>

             </ol>
           </MainTextSizeChanged>
         </Col>
       </Row>

       {getSeperatorWithHeight(18)}
       <Row>
         {getColOf3()}
         <Col md={6}>

           <Header4WithLeftBorder>
             Storyboard
           </Header4WithLeftBorder>
           {getSeperatorWithHeight(10)}
           <ZoomableImageWithModal image={storyboard2Image} header={"Design Alternative 2 Storyboard"}/>
         </Col>
       </Row>
     </StyledContainer>
    )
  }

}

export default DesignAlternative2;