import {Row, Col, Container} from 'reactstrap'
import styled from 'styled-components'
import React from 'react'
import { getColOf3, getSeperatorWithHeight } from '../text_style'
import {MainText1, Header2WithLeftBoarder, Header2SubtitleWithLeftBorder} from './hci_foundation_style'
import { StyledContainer } from '../layout'
import TrackVisibility from "react-on-screen"
import { MainText1Bold } from './hci_foundation_style'
import { AnimatedText } from './hci_common_component'


class IdeationIntro extends React.Component{

    render(){

    return(

        <StyledContainer fluid={true}>
            <Row>
              {getColOf3()}
              <Col md={6} > <Header2WithLeftBoarder> Ideation </Header2WithLeftBoarder> </Col>
            </Row>

            <Row>
              {getColOf3()}
              <Col md={6}> <Header2SubtitleWithLeftBorder> Explore different possible
                solutions for gift buying </Header2SubtitleWithLeftBorder></Col>
            </Row>

            {getSeperatorWithHeight(28)}

            <Row>
              {getColOf3()}
              <Col md={6}>
                <TrackVisibility>
                  <AnimatedText text={"To generate possible solutions and validate them, we follow these steps:"}/>
                </TrackVisibility>

                <MainText1>
                  <ol style={{paddingLeft: `25px`}}>
                    <li >
                      <MainText1Bold> Brainstorming </MainText1Bold>
                       to generate a large amount of ideas
                    </li>

                    <li >
                      Synthesize ideas into <MainText1Bold> three design alternatives </MainText1Bold>
                    </li>

                    <li >
                      Develop a set of <MainText1Bold> design direction </MainText1Bold> after
                      <MainText1Bold> user feedback</MainText1Bold>
                    </li>
                  </ol>

                </MainText1>
              </Col>
            </Row>



        </StyledContainer>

    )
    }
}

export default IdeationIntro;