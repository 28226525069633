import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {Header2, getColOf3, getSeperatorWithHeight, HeaderForSubtitle} from '../text_style'
import storyboardImage from '../../images/infovis/Storyboard for prototype.jpg'
import { StyledContainer } from '../layout'
import {Header3, MainText1} from './infovis_style'

class StoryboardInfoVis extends React.Component{
  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header3>
              Storyboard
            </Header3>

            {getSeperatorWithHeight(28)}
            <MainText1>
              Based on our directions, we sketched out a storyboard for our story telling design. We spent
              a lot of time in deciding the story of our design.  At last, we decided to tell a story about waiting
              list from multiple angles. We will examine from three levels, first hospital level, then state level
              and finally the nation as a whole over years. We want them to see how the situation evolves from
              different time and space.
            </MainText1>

          </Col>
        </Row>
        {getSeperatorWithHeight(28)}

        <Row>
          {getColOf3()}
          <Col md={6} >
            <img src={storyboardImage} className="img-fluid" alt={"The storyboard for infovis"}/>
          </Col>
        </Row>
      </StyledContainer>
    )
  }
}

export default StoryboardInfoVis