import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import indicator from '../../images/infovis/Indicator-infovis.svg'
import indicator2 from '../../images/infovis/Indicator2-infovis.svg'
import indicator3 from '../../images/infovis/Indicator3-infovis.svg'
import indicator4 from '../../images/infovis/Indicator4-infovs.svg'
import { StyledContainer } from '../layout'
import { displayFont, displayFontWeight, mainTextColor, Header2WithLeftBoarder, textFont } from './infovis_style'

let StyledLine = styled.div`

  font-family: ${textFont};
  font-size: 16px;
  line-height: 28px;
  color: ${mainTextColor};
  text-align: left;
`

let StepHeader = styled.div`

  font-family: ${displayFont};
  font-weight: ${displayFontWeight.regular};
  font-size: 24px;
  line-height: 34px;
  color: ${mainTextColor};
  text-align: left;
`


let Header2 = styled.div`
  font-family: OpenSans-Bold;
  font-size: 36px;
  color: #161616;
  letter-spacing: -0.5px;
`

let contentList1 = ["Competitive analysis",
  "Literature review",
  "Data Analysis",
  "Affinity Diagram"
  ]

let contentList2 = ["Sketching",
  "Brainstorming",
  "Paper Sketching",
  "Low fidelity prototype",
  "Sketch",
  "Poster for feedback"
  ]

let contentList3 = [
  "Storyboard",
  "Video PrototypeInfoVis",
  "Principle",
  "Sketch"
]

let contentList4 = [
  "Web Development",
  "Video Presentation"
]


class Step extends React.Component{

  render(){
    return(
      <div>
        <StepHeader> {this.props.header} </StepHeader>

        <img src={this.props.indicator} style={{height:"6px", marginBottom:`5px`}} className="img-fluid" alt="Responsive image"/>

        {this.props.contentList.map(d => {
          return(
            <StyledLine> {d} </StyledLine>)
        })}
      </div>
    )
  }
}

class ProcessInfoVis extends React.Component{

  render() {
    return (

      <StyledContainer fluid={true}>

        <Row style={{paddingBottom:`16px`}}>
          <Col md={3}> </Col>
          <Col md={6}> <Header2WithLeftBoarder> Process </Header2WithLeftBoarder></Col>
        </Row>

        <Row>
          <Col md={3}> </Col>
          <Col md={2}> <Step header={"Research"} contentList={contentList1} indicator={indicator} /> </Col>
          <Col md={2}> <Step header={"Ideation"} contentList={contentList2} indicator={indicator2} /> </Col>
          <Col md={2}> <Step header={"Prototype"} contentList={contentList3} indicator={indicator3} /> </Col>
          <Col md={2}> <Step header={"Development"} contentList={contentList4} indicator={indicator4} /> </Col>
        </Row>

      </StyledContainer>
    )
  }
}

export default ProcessInfoVis