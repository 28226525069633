import React from 'react'
import { Link } from 'gatsby'

import { Layout } from '../components/layout'
import SEO from '../components/seo'
import Scrollspy from 'react-scrollspy'

import IntroInfoVis from '../components/info_vis/intro_infovis'
import { Row } from 'reactstrap'

import { getSeperatorWithHeight } from '../components/text_style'
import ProcessInfoVis from '../components/info_vis/process_infovis'
import ProblemStatementInfoVis from '../components/info_vis/problem_statement_infovis'
import ResearchIntroInfoVis from '../components/info_vis/research_intro_infovis'
import LiteratureReviewInfoVis from '../components/info_vis/literature_review_info_vis'
import DataAnalysisInfovis from '../components/info_vis/data_analysis_infovis'
import AffinityDiagramInfovis from '../components/info_vis/affinity_diagram_infovis'
import FindingsInfoVis from '../components/info_vis/surprising_findings_infovis'
import AudienceInfoVis from '../components/info_vis/audience_infovis'
import IdeationIntro from '../components/hci_foundation/ideation_intro'
import IdeationIntroInfoVis from '../components/info_vis/ideation_intro_introvis'
import BrainstormingInfoVis from '../components/info_vis/brainstorming_infovis'
import DesignAlternative1InfoVis from '../components/info_vis/design_alternative_1_infovis'
import DesignAlternative2 from '../components/hci_foundation/design_alternatives_2'
import DesignAlternative2InfoVis from '../components/info_vis/design_alternative_2_infovis'
import DesignAlternative3InfoVis from '../components/info_vis/design_alternative_3_infovis'
import DesignAlternative4InfoVis from '../components/info_vis/design_alternative_4_infovis'
import IdeationFeedbackInfoVis from '../components/info_vis/ideation_feedback'
import PrototypeIntroInfoVis from '../components/info_vis/prototype_intro_infovis'
import DesignDirectionInfoVis from '../components/info_vis/design_direction_infovis'
import StoryboardInfoVis from '../components/info_vis/storyboard_infovis'
import PrototypeInfoVis from '../components/info_vis/prototype_infovis'
import InteractivePrototypeInfovis from '../components/info_vis/interaction_prototype_infovis'
import AnimatedPrototypeInfovis from '../components/info_vis/animated_prototype_infovis'
import DevelopmentIntroInfoVis from '../components/info_vis/development_intro_infovis'
import DevelopmentToolsInfoVis from '../components/info_vis/development_tools_infovis'
import FinalProductInfoVis from '../components/info_vis/final_product_infovis'
import { SectionSeperator } from '../components/info_vis/infovis_common_component'
import Footer from './footer'

const InfoVis = () => (
  <Layout>
    <SEO title="Information visualization" />

    {getSeperatorWithHeight(50)}

    <Scrollspy
      items={[
        'overview',
        'process',
        'problem_statement',
        'research',
        'ideation',
        'prototype',
        'development',
      ]}
      style={{ position: 'fixed', paddingTop: '48px', listStyleType: 'none' }}
      currentClassName="is-current-infovis"
      scrolledPastClassName={'is-past-infovis'}
      offset={-30}
      className={'sidebar'}
    >
      <li className={'scollli'}>
        <a href="#overview">Overview</a>
      </li>
      <li className={'scollli'}>
        <a href="#process">Process</a>
      </li>
      <li className={'scollli'}>
        <a href="#problem_statement">Problem Statement</a>
      </li>
      <li className={'scollli'}>
        <a href="#research">Research</a>
      </li>
      <li className={'scollli'}>
        <a href="#ideation">Ideation</a>
      </li>
      <li className={'scollli'}>
        <a href="#prototype">Prototype</a>
      </li>
      <li className={'scollli'}>
        <a href="#development">Development</a>
      </li>
    </Scrollspy>

    <section id="overview">
      <IntroInfoVis />
    </section>

    <section id={'process'}>
      {getSeperatorWithHeight(50)}
      <SectionSeperator />
      {getSeperatorWithHeight(50)}
      <ProcessInfoVis />
    </section>

    <section id={'problem_statement'}>
      {getSeperatorWithHeight(50)}
      <SectionSeperator />
      {getSeperatorWithHeight(50)}
      <ProblemStatementInfoVis />
    </section>

    <section id={'research'}>
      {getSeperatorWithHeight(50)}
      <SectionSeperator />
      {getSeperatorWithHeight(50)}
      <ResearchIntroInfoVis />
      {getSeperatorWithHeight(80)}

      <LiteratureReviewInfoVis />
      {getSeperatorWithHeight(80)}

      <DataAnalysisInfovis />
      {getSeperatorWithHeight(80)}

      <AffinityDiagramInfovis />
      {getSeperatorWithHeight(80)}

      <FindingsInfoVis />
      {getSeperatorWithHeight(80)}

      <AudienceInfoVis />
    </section>
    
    <section id={'ideation'}>
      {getSeperatorWithHeight(50)}
      <SectionSeperator />
      {getSeperatorWithHeight(50)}
      <IdeationIntroInfoVis />
      {getSeperatorWithHeight(80)}

      <BrainstormingInfoVis />
      {getSeperatorWithHeight(80)}

      <DesignAlternative1InfoVis />
      {getSeperatorWithHeight(80)}

      <DesignAlternative2InfoVis />
      {getSeperatorWithHeight(80)}

      <DesignAlternative3InfoVis />
      {getSeperatorWithHeight(80)}

      <DesignAlternative4InfoVis />
      {getSeperatorWithHeight(80)}

      <IdeationFeedbackInfoVis />
    </section>

    <section id={'prototype'}>
      {getSeperatorWithHeight(50)}
      <SectionSeperator />
      {getSeperatorWithHeight(50)}

      <PrototypeIntroInfoVis />
      {getSeperatorWithHeight(80)}

      <DesignDirectionInfoVis />
      {getSeperatorWithHeight(80)}

      <StoryboardInfoVis />
      {getSeperatorWithHeight(80)}

      <PrototypeInfoVis />
      {getSeperatorWithHeight(52)}

      <InteractivePrototypeInfovis />
      {getSeperatorWithHeight(80)}

      <AnimatedPrototypeInfovis />
    </section>

    <section id={'development'}>
      {getSeperatorWithHeight(50)}
      <SectionSeperator />
      {getSeperatorWithHeight(50)}

      <DevelopmentIntroInfoVis />
      {getSeperatorWithHeight(80)}

      <DevelopmentToolsInfoVis />
      {getSeperatorWithHeight(80)}

      <FinalProductInfoVis />
      {getSeperatorWithHeight(80)}
    </section>

    {getSeperatorWithHeight(100)}

    {getSeperatorWithHeight(80)}
    <Row className="d-flex justify-content-center">
      <a style={{ color: `#ADADAD` }} href="#">
        Back to top
      </a>
    </Row>

    {getSeperatorWithHeight(20)}
    <Footer />
  </Layout>
)

export default InfoVis
