import React from 'react'
import {Row, Col, Container, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap'

import styled from 'styled-components'
import sectionSeperator from "../../images/section-seperator.svg"
import {
  Title,
  Header2SubtitleWithLeftBorder,
  Header2WithLeftBoarder,
  HoverAnimatedMainText1,
} from './infovis_style'
import { getColOf3 } from '../text_style'
import {ZoomableImage} from './infovis_style'

export const AnimatedText = ({ isVisible, text }) => {
  const backgroundPosition= isVisible ? '-99.99% 0;' : '-0% 0;'
  console.log(backgroundPosition)

  const highlightClass = isVisible? "highlight" : ""

  return (
    <HoverAnimatedMainText1 className={highlightClass} style={{
      marginBottom: `11px`
    }}> {text} </HoverAnimatedMainText1>
  );
}

export class SectionSeperator extends React.Component{

  render () {
    return (
    <Row>
      <Col md={4}/>
      <Col md={4} >
        <img src={sectionSeperator} className="img-fluid" alt={"section seperator"} />
      </Col>
    </Row>
    )
  }
}

export class ZoomableImageWithModal extends React.Component{

  constructor(props){
    super(props)
    this.state = {modalOpen: false};

  }

  toggle = () => {
    this.setState({modalOpen: !this.state.modalOpen})
  }

  render(){

    return(
      <Container>
        <ZoomableImage style={{paddingRight: `5%`}} src={this.props.image} alt={this.props.header} className={"img-fluid"} onClick={this.toggle}/>
        <Modal isOpen={this.state.modalOpen} size='xl'>
          <ModalHeader toggle={this.toggle}>{this.props.header} </ModalHeader>
          <ModalBody>
            <Container>
              <img src={this.props.image} className={"img-fluid"}/>
            </Container>
          </ModalBody>

        </Modal>
      </Container>

    )
  }
}