import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {Header2, getColOf3, getSeperatorWithHeight, HeaderForSubtitle} from '../text_style'
import affinityDiagramImage from '../../images/infovis/Affinity-diagram.jpg'
import { StyledContainer } from '../layout'
import {MainText1, Header3} from './infovis_style'

class AffinityDiagramInfovis extends React.Component{
  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header3>
              Affinity Diagram
            </Header3>
          </Col>
        </Row>

        {getSeperatorWithHeight(28)}

        <Row>
          {getColOf3()}
          <Col md={4}>
            <MainText1>
              We created an affinity diagram in a small scale to discuss about all we know as a team.
              Each of us put down the knowledge, data, term on a sticky note on the wall, and then organize
              them. It helped our team to have a collective focus on what each person knows and transfer
              the individual knowledge to others who didn’t know. It also helps us discover relationships
              between different concepts and see how we can organize them for our ideation process.
            </MainText1>
          </Col>
          <Col md={2}>
            <img src={affinityDiagramImage} className={"img-fluid"} alt={"affinity diagram"}/>
          </Col>

        </Row>

      </StyledContainer>

    )
  }
}

export default AffinityDiagramInfovis