import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {Header2,getColOf3, getSeperatorWithHeight, HeaderForSubtitle} from '../text_style'
import {Header3, MainText1} from './infovis_style'
// import researchCroppedImage from '../../images/Research-cropped.jpg'
import section1Image from '../../images/infovis/Personal story.png'
import section2Image from '../../images/infovis/Transition.png'
import section3Image from '../../images/infovis/Feature3.png'
import section4Image from '../../images/infovis/Feature4.png'
import { StyledContainer } from '../layout'


let SectionHeader = styled.div`
  font-family: OpenSans-SemiBold;
  font-size: 16px;
  color: #161616;
  letter-spacing: -0.67px;
`

let sectionData = [
  {
    header:"Section 1" ,
    text: "The first part of our prototype originates from our design alternative 1. Since we want the audience to" +
    " have a personal touch, we invited the reader of our story to imagine themselves" +
    " as the patient and consider the similar decisions about hospitals. " +
    " Compared to design alternative 1, we add the ability to find hospitals based on zip code entered," +
    " so that the reader can have more freedom to explore other regions. Zip code provides detailed information " +
    "about the location easily. Hospitals will be marked on the zoomed map so that the reader can intuitively" +
    " locate the hospitals. On the right hand side, the scatterplot is preserved to show the 3-year death rate and the" +
    " transplantation rate. We also added a line graph to show the  waiting time distribution for those hospitals shown in the map.  ",
    img: section1Image,
    alt:"section1",
  },

  {
    header:"Section 2",
    text: "Due to the nature of our story telling, we added texts to help our transit between different visualization. " +
    "In this section, we provide a paragraph telling the user about the waiting list in the state they choose, " +
    "which they can relate to. Then the user will get informed on the serious situation California is facing. " +
    "We then inform users about why the geography is more important in waiting list and lay the foundation for" +
    " the next visualization about state level information.",
    img: section2Image,
    alt:"section2"
  },
  {
    header: "Section 3",
    text: "This visualization represents state level information about the waiting list to donor ratio, which is " +
    "indicated by the color. The darker, the less serious. The ratio is helpful to compare the situation " +
    "in the state, because it reduces the effect the population of each state has on directly comparing the " +
    "waiting list length. The map with all its controls provide users with a lot of information. So they can" +
    " change the controls to better understand it. If they want information related to themselves, users can " +
    "choose their own blood type and BMI and  see the ratio. The map also provides an multi angles on the population of patients.\n",
    img: section3Image,
    alt: "section 3"
  },
  {
    header: "Section 4",
    text: "This visualization is our solution to animate the change of waiting list over years. We keep animation because" +
    " we think the change itself provides the most information on this issue. The overall shape of different areas provides" +
    " big picture while the movement of pixels provide details for each year. The big square in the middle represents " +
    "the waiting list. Its size represents the size of the waiting list. Each pixel stands for a person. We want users" +
    " to actual feel the large number of people visually. As the animation starts, the pixels on the left will float to " +
    "the middle square, representing the patients who entered the waiting list. At the same time, some pixels in the" +
    " middle move to the right side, and grouped into four different categories. Each category represents one removal " +
    "reasons for waiting list. Users can change the slider to see this animation for the specific year. On the right " +
    "side of the design, a line chart presents a simple overview of waiting list changes and donor number changes over " +
    "the time. At last, we add a call-to-action to call for registering as a donor.  ",
    img: section4Image,
    alt: "section 4"
  }
]



class PrototypeInfoVis extends React.Component{

  render(){
    return(
      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header3>
              High-Fidelity Prototype
            </Header3>

            {getSeperatorWithHeight(28)}
            {
              sectionData.map( d => {
                  return (
                   <div>
                     <SectionHeader>
                       {d.header}
                     </SectionHeader>
                     {getSeperatorWithHeight(12)}

                     <MainText1>
                       {d.text}
                     </MainText1>

                     {getSeperatorWithHeight(12)}
                     <img src={d.img} className="img-fluid" alt={d.alt}/>

                     {getSeperatorWithHeight(28)}
                   </div>
                  )
                }
              )
            }
          </Col>
        </Row>

      </StyledContainer>
    )
  }
}

export default PrototypeInfoVis