import React from 'react'
import {Row, Col, Container, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap'

import styled from 'styled-components'
import sectionSeperator from "../../images/section-seperator.svg"
import {
  Title,
  Header2SubtitleWithLeftBorder,
  Header2WithLeftBoarder,
  HoverAnimatedMainText1, displayFontWeight, textFont, textFontWeight,
} from './hci_foundation_style'
import { getColOf3 } from '../text_style'
import {ZoomableImage} from './hci_foundation_style'

export class SectionIntroHeader extends React.Component{

  constructor( props ){
    super(props)
    this.state = {
      header: props.header,
      subtitle: props.subtitle
    }
  }

  render(){
    return(
      <div>
        <Header2WithLeftBoarder> {this.props.header}</Header2WithLeftBoarder>
        <Header2SubtitleWithLeftBorder> {this.props.subtitle} </Header2SubtitleWithLeftBorder>
      </div>
    )
  }
}

export const AnimatedText = ({ isVisible, text }) => {
  const backgroundPosition= isVisible ? '-99.99% 0;' : '-0% 0;'
  console.log(backgroundPosition)

  const highlightClass = isVisible? "highlight" : ""

  return (
    <HoverAnimatedMainText1 className={highlightClass} style={{
      marginBottom: `11px`
    }}> {text} </HoverAnimatedMainText1>
  );
}

export class SectionSeperator extends React.Component{

  render () {
    return (
    <Row>
      <Col md={4}/>
      <Col md={4} >
        <img src={sectionSeperator} className="img-fluid" alt={"section seperator"} />
      </Col>
    </Row>
    )
  }
}

export class ZoomableImageWithModal extends React.Component{

  constructor(props){
    super(props)
    this.state = {modalOpen: false};

  }

  toggle = () => {
    this.setState({modalOpen: !this.state.modalOpen})
  }

  render(){

    return(
      <Container>
        <ZoomableImage style={{paddingRight: `0%`}} src={this.props.image} alt={this.props.header} className={"img-fluid"} onClick={this.toggle}/>
        <Modal isOpen={this.state.modalOpen} size='xl'>
          <ModalHeader toggle={this.toggle}>{this.props.header} </ModalHeader>
          <ModalBody>
            <Container style={{textAlign: "center"}}>
              <img src={this.props.image}/>
            </Container>
          </ModalBody>

        </Modal>
      </Container>
    )
  }
}

let RightSideBarDiv = styled.div`
    // right side bar   
    font-family: OpenSans;
    font-size: 15px;
    letter-spacing: 0 px; 
    border-left: 7px solid #FB6D6D;
    line-height: 26px;
    margin-left: 10%;
    padding-left: 10px;
    padding-right: 25%;
`

export class RightSideBar extends React.Component{
  // It accepts two props: textHeader, textBody

  render() {
    return (
      <Col md={3}>
        <RightSideBarDiv>

          <div style={{
            color: "#FF3939",
            paddingBottom: "8px",
            lineHeight: "20px",
            fontFamily: `${textFont}`,
            fontWeight: `${displayFontWeight.bold}`
          }}>
            {this.props.textHeader}
          </div>
          <div style={{ color: "#FF3939", fontFamily: `${textFont}`, fontWeight: `${textFontWeight.light}` }}>
            {this.props.textBody}
          </div>

        </RightSideBarDiv>
      </Col>
    )
  }
}