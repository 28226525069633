import React from 'react'
import {Row, Col, Container, Button, Modal, ModalBody, ModalHeader} from 'reactstrap'

import styled from 'styled-components'

import {Header2, getColOf3, getSeperatorWithHeight, HeaderForSubtitle, ZoomableImage} from '../text_style'
import designAlternative2Image from '../../images/infovis/design_alternative_2.png'
import { StyledContainer } from '../layout'
import {MainText1,Header3} from './infovis_style'

class DesignAlternative2InfoVis extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  click = () =>{
    console.log("Click")
  }

  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={4}>
            <Header3>
              Design Alternative 2
            </Header3>

            {getSeperatorWithHeight(28)}

            <MainText1>
              This visualization attempts to tell the story of the constant change in waiting list over years.
              The waiting list of each year will be presented as a line with multiple human icons. Each human
              icon represents 3000 individuals. Colors of human icons represents different groups of patients
              on waiting list for each year. Green represents the cured,  black for the death, purple for the
              newly added and blue for those who still waits. The story guides the user to understand the patients
              change on waiting list in one year. In the visualization at the bottom, the user can change the
              slider to see how waiting list changes over the years and what ending patients on the waiting list have for each year.
            </MainText1>
          </Col>

          <Col md={3}>
            <ZoomableImage src={designAlternative2Image} alt="Design alternative 1" className="img-fluid" onClick={this.toggle}/>

            <Modal isOpen={this.state.modal} size='lg'>
              <ModalHeader toggle={this.toggle}>User Journey Map</ModalHeader>
              <ModalBody>
                <Container>
                  <img src={designAlternative2Image} className={"img"} alt="User Journey Map"/>
                </Container>
              </ModalBody>
            </Modal>
          </Col>
        </Row>

      </StyledContainer>
    )
  }
}

export default DesignAlternative2InfoVis