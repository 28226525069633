import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {Header2, getColOf3, getSeperatorWithHeight, HeaderForSubtitle} from '../text_style'
import { StyledContainer } from './../layout'

import {MainText1,Header3} from './infovis_style'

class FindingsInfoVis extends React.Component{
  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header3>
              Findings
            </Header3>

            {getSeperatorWithHeight(28)}
            <MainText1>
              We have some surprising findings  in our domain research. Below are some of them worthy to get more
              attention from general public:
            </MainText1>

            {getSeperatorWithHeight(12)}
            <MainText1>
              <ul>
                <li> Matching process between organ donors and patients are highly complicated, involving more than
                  6 to 7 variables, making predicting the length of waiting really hard. Those variables include BMI,
                  Blood type, waiting year, DPRI (an indication for possible success rate), patient several rate,
                  distance between donor and patient, age.  </li>
                <li>
                  The patient has to carefully pick hospitals to join on waiting list because they need to rush
                  to hospitals when the organs are matched, especially for patients who needs lung, heart.
                  Patients need to live close by. When picking hospitals, patients need to know hospitals
                  transplantation rate, death rate and other information. Each hospital is different.
                </li>

                <li>
                  The kidney is the most demanded organ among all organs possible for transplantation, even
                  though the kidney allows living donation. The demand ratio between kidney and liver, the
                  second largest demand right now is around 8:1. Not even mentioning other organs such as heart
                  and lung.
                </li>

                <li>
                  California, and Alabama have the most serious problems in kidney transplantation. Hospitals in
                  those states usually have over 50% of patients waiting over 3 years. The severity of situation
                  about the waiting list on Kidney varies from state to state, hospital to hospital.
                </li>

                <li>
                  The waiting list length for kidneys has increased dramatically in the last two decades,
                  while the number of donors increases really slowly over the years. The increase in waiting
                  list is also caused by the fact that the amount of addition always surpasses the amount of
                  the removal each year.
                </li>

                <li>
                  Over 20 people die on the waiting list everyday. And roughly the same number of people
                  experience health deterioration, leading to their inability to receive transplantation.
                </li>
              </ul>
            </MainText1>
          </Col>
        </Row>
        
      </StyledContainer>
    )
  }
}

export default FindingsInfoVis