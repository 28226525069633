import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {Header2, MainText1, getColOf3, getSeperatorWithHeight, HeaderForSubtitle} from '../text_style'
import toolsImage from '../../images/infovis/Tool_we_use.svg'
import { StyledContainer } from '../layout'
import {Header3} from './infovis_style'

class DevelopmentToolsInfoVis extends React.Component{
  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header3>
              Development Tools
            </Header3>

            {getSeperatorWithHeight(28)}
            <img src={toolsImage} className="img-fluid" alt={"Tools image"}/>

          </Col>
        </Row>

      </StyledContainer>
    )
  }
}

export default DevelopmentToolsInfoVis