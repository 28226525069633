import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {Header2, getColOf3, getSeperatorWithHeight, HeaderForSubtitle} from '../text_style'
import { StyledContainer } from '../layout'
import {MainText1,Header3} from './infovis_style'

class AudienceInfoVis extends React.Component{
  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header3>
              Our Audience/User
            </Header3>

            {getSeperatorWithHeight(28)}
            <MainText1>
              <span style={{fontFamily:"OpenSans-SemiBold"}}> General public </span>
              as our audience. We originally target patients.
              However, as we dig into the information, we realize the solution
              of this issue requires the understanding and actions from the public.
              In addition, data we obtain provides a broader perspective on this issue,
              which is better at describing the situation for those who are not familiar with the topic.
              Thus, we steered our target audience toward general public who doesn’t have too
              much knowledge on organ donation. Our assumption about the audience is that they don’t
              know much about decisions patients have to make, and have no clue about the size of the waiting list.
            </MainText1>
          </Col>
        </Row>

      </StyledContainer>
    )
  }
}

export default AudienceInfoVis