import React from 'react'
import {Row, Col, Container, Button, Modal, ModalBody, ModalHeader} from 'reactstrap'

import styled from 'styled-components'

import {Header2, getColOf3, getSeperatorWithHeight, HeaderForSubtitle, ZoomableImage} from '../text_style'
import designAlternative4Image from '../../images/infovis/design_alternative_4.png'
import { StyledContainer } from '../layout'
import {MainText1,Header3} from './infovis_style'

class DesignAlternative4InfoVis extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  click = () =>{
    console.log("Click")
  }
  
  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={4}>
            <Header3>
              Design Alternative 4
            </Header3>

            {getSeperatorWithHeight(28)}

            <MainText1>
              In United States, each of the 50 states has several transplant
              centers and each transplant center has its own statistics which
              concerns any patient who is looking for transplantation. This
              visualization attempts to convey the  the waiting list distribution
              and the hierarchical relationships among these entities in one giant
              vis with some interactions. Inspired from the Sankey Diagrams, it
              grows from the bottom to top. The first level shows all organs available
              for transplantation and allows the user to choose one to drill down into next level.
              The second level indicates the waiting list length for each state.  The user can further
              drill down to see the transplant centers in each state by choosing one of the them. The
              third level shows the waiting list length of each hospital, and 3 year transplantation
              rate on a bar chart.
            </MainText1>
          </Col>

          <Col md={3}>
            <ZoomableImage src={designAlternative4Image} alt="Design alternative 4" className="img-fluid" onClick={this.toggle}/>

            <Modal isOpen={this.state.modal} size='lg'>
              <ModalHeader toggle={this.toggle}>User Journey Map</ModalHeader>
              <ModalBody>
                <Container>
                  <img src={designAlternative4Image} className={"img"} alt="Design alternative 4"/>
                </Container>
              </ModalBody>
            </Modal>
          </Col>
        </Row>

      </StyledContainer>
    )
  }
}

export default DesignAlternative4InfoVis