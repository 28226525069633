import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {Header2, getColOf3, getSeperatorWithHeight, HeaderForSubtitle} from '../text_style'
import sketchOnIPadImage from '../../images/infovis/Sketch_on_iPad.png'
import sketchForBubbleChartImage from '../../images/infovis/Sketch_for_bubblechart.jpg'
import sketchForMultiviewImage from '../../images/infovis/Sketch-For_multiview.jpg'
import { StyledContainer } from '../layout'
import {MainText1,Header3} from './infovis_style'

class BrainstormingInfoVis extends React

.Component{
  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header3>
              Brainstorming
            </Header3>

          </Col>
        </Row>
        {getSeperatorWithHeight(28)}

        <Row>
          {getColOf3()}
          <Col md={3}>
            <MainText1>
              Four of us met together and tossed ideas by sketched them out on the paper and the iPad.
            </MainText1>
          </Col>

          <Col md={3}>
              <img src={sketchForMultiviewImage} className={"img-fluid"} alt={"Sketching on iPad"}/>
          </Col>
        </Row>
        {getSeperatorWithHeight(16)}

        <Row>
          {getColOf3()}
          <Col md={3}>
            <MainText1>
              <img src={sketchOnIPadImage} className={"img-fluid"} alt={"Sketching on bubble chart"}/>
            </MainText1>
          </Col>

          <Col md={3}>
              <img src={sketchForBubbleChartImage} className={"img-fluid"} alt={"Sketching for multiview"}/>
          </Col>
        </Row>

      </StyledContainer>
    )
  }
}

export default BrainstormingInfoVis