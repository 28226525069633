import React from 'react'
import {Row, Col} from 'reactstrap'

import { getColOf3, getSeperatorWithHeight} from '../text_style'
import { StyledContainer } from '../layout'
import {Header2WithLeftBoarder, MainText1, Header2SubtitleWithLeftBorder} from './infovis_style'

class ResearchIntroInfoVis extends React.Component {


  render(){
    return(

      <StyledContainer fluid={true} >
        <Row>
          {getColOf3()}
          <Col md={6}> <Header2WithLeftBoarder> Research </Header2WithLeftBoarder> </Col>
        </Row>

        <Row>
          {getColOf3()}
          <Col md={6}> <Header2SubtitleWithLeftBorder>Dive into the domain of the organ donation</Header2SubtitleWithLeftBorder></Col>
        </Row>

        {getSeperatorWithHeight(32)}

        <Row>
          {getColOf3()}
          <Col md={6}>
            <MainText1>
              In information visualization, understanding what message the data conveys is crucial to designers.
              As designers, we need find the best visualization presentation and user interaction of the data that
              enables the audience to get their own insights quickly. Thus, when we realize we were so ignorant on
              any of variables in our dataset about organ transplantation, we decided to take time to understand the
              organ transplantation through literature review and data analysis.
            </MainText1>
          </Col>
        </Row>

        {getSeperatorWithHeight(32)}

      </StyledContainer>

    )
  }
}

export default ResearchIntroInfoVis