import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight} from '../text_style'
import optnQuiryImage from '../../images/infovis/optn_quiry.png'
import dataImage from '../../images/infovis/Data_photo.jpg'
import srtrImage from '../../images/infovis/SRTR.png'

import { StyledContainer } from '../layout'
import { MainText1, Header3, ImageCaption } from './infovis_style'


class DataAnalysisInfovis extends React.Component{
  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header3>
              Data Analysis
            </Header3>

            {getSeperatorWithHeight(28)}
            <MainText1>
              As we gradually dig deeper in the design, we discovered two huge data source for this topic.
              One is the database of Organ Procurement and Transplantation Network (OPTN), the organization
              that manages organ transplantation, and the other is database from SRTR, an organization that
              focuses on reporting the organ transplantation data from each center. The database of OPTN has
              the most comprehensive variables. It covers data about both patients and donors on each state,
              each center, and each year back to 1988. We spent much timing in making sense of its structure
              and filtering out variables that help us present the big picture. The SRTR has the best report
              that makes sense of the performance of each transplantation center. In addtion, We did some
              basic statistics on some data.
            </MainText1>

          </Col>
        </Row>
        {getSeperatorWithHeight(16)}

        <Row>
          <Col md={2}/>
          <Col md={4} >
            <img src={optnQuiryImage} className={"img-fluid"} alt={"Quiry for OPTN"}/>

            <Row className="d-flex justify-content-center">
              <p> <ImageCaption> OPTN database </ImageCaption> </p>
            </Row>
          </Col>

          <Col md={4} >
            <img src={srtrImage} className={"img-fluid"} alt={"Quiry for SRTR"}/>

            <Row className="d-flex justify-content-center">
              <p> <ImageCaption> SRTR website </ImageCaption> </p>
            </Row>

          </Col>
        </Row>

        {getSeperatorWithHeight(16)}

        <Row>
          <Col md={2}/>

          <Col md={8} >
            <img src={dataImage} className={"img-fluid"} alt={"data variables"}/>
            <Row className="d-flex justify-content-center">
              <p> <ImageCaption> Data variables</ImageCaption> </p>
            </Row>
          </Col>
        </Row>


      </StyledContainer>
    )
  }
}

export default DataAnalysisInfovis