import React from 'react'
import {Row, Col, Container, Button} from 'reactstrap'

import styled from 'styled-components'
const windowGlobal = typeof window !== 'undefined' && window
// import GifPlayer from 'react-gif-player'

import {Header2, getColOf3, getSeperatorWithHeight, HeaderForSubtitle, Caption} from '../text_style'
// import animatedPrototypeGif from '../../images/infovis/animated.gif'
import animatedPrototypeGif from '../../images/infovis/animated-loop-infinity.gif'
import stillImage from '../../images/infovis/animated_still.jpg'
import { StyledContainer } from '../layout'
import {Header3, MainText1, ImageCaption} from './infovis_style'

class AnimatedPrototypeInfovis extends React.Component{
  constructor(props){
    super(props)
    this.state= {gifToggle:false, loading: true}
  }

  toggle = ()=> {
    this.setState({gifToggle: !this.state.gifToggle})
  }

  componentDidMount = () =>{
  }

  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header3>
              Animated Prototype
            </Header3>

            {getSeperatorWithHeight(28)}

          </Col>
        </Row>

        <Row>
          <Col md={3}/>
          <Col md={3}>
            <MainText1>
              Due to the difficulty of using Principle for complicated animation, we created a crude animated prototype
              with Javascript to demonstrate how animation might look like.
            </MainText1>
          </Col>
          <Col md={3} >
            {/* {
              this.state.loading ? <div></div> : <GifPlayer style={{position:"relative", marginBottom:"4px"} } gif={animatedPrototypeGif} still={stillImage} onTogglePlay={this.state.gifToggle}/>
            }
             */}
              <img src={animatedPrototypeGif}></img>

              {/* <ImageCaption>
                Click image to play
              </ImageCaption> */}

          </Col>

        </Row>
      </StyledContainer>
    )
  }
}

export default AnimatedPrototypeInfovis