import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight,
  HeaderForSubtitleWithLeftBorder,
  HeaderForSubtitle} from '../text_style'
import researchCroppedImage from '../../images/hci_foundation/Research-cropped.jpg'
import { StyledContainer } from '../layout'
import {Header2WithLeftBoarder, MainText1, Header2SubtitleWithLeftBorder} from './infovis_style'

class DevelopmentIntroInfoVis extends React

.Component {

  render(){
    return(

      <StyledContainer fluid={true} >
        <Row>
          {getColOf3()}
          <Col md={6}> <Header2WithLeftBoarder> Development </Header2WithLeftBoarder> </Col>
        </Row>

        <Row>
          {getColOf3()}
          <Col md={6}> <Header2SubtitleWithLeftBorder>Turn our ideas of visualization into the reality with code</Header2SubtitleWithLeftBorder></Col>
        </Row>

        {getSeperatorWithHeight(32)}

        <Row>
          {getColOf3()}
          <Col md={6}>
            <MainText1>
              With our prototype as our guidance, we started the development using web technology.
              We cleaned our data files using python and built the website using web stack.
              Our design, although not overhauled, was further modified to adapt to our previous feedback and technology tools.
            </MainText1>
          </Col>
        </Row>
      </StyledContainer>

    )
  }
}

export default DevelopmentIntroInfoVis