import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {Header2, getColOf3, getSeperatorWithHeight, HeaderForSubtitle} from '../text_style'
// import researchCroppedImage from '../../images/Research-cropped.jpg'
import { StyledContainer } from '../layout'
import {MainText1,Header3} from './infovis_style'

class IdeationFeedbackInfoVis extends React.Component{
  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header3>
              Feedback
            </Header3>

            {getSeperatorWithHeight(28)}
            <MainText1>
              To collect feedback, we created a poster to illustrate the background of organ transplantation
              and our design alternatives. We then  presented to all of our classmates, teaching assistants and also professor.
            </MainText1>

            {getSeperatorWithHeight(12)}

            <MainText1>
              <ul>
                <li>
                  Some of our design alternatives focus too narrow on one topic. Since not a lot of people know about
                  situation in organ transplantation, therefore, showing the big picture is important.
                </li>
                <li>
                  Decisions related to organ transplantation are personal for both donors and patients, so
                  the visualization should have more personal touch and let the audience understand that there
                  are real people waiting for their help behind the numbers we show.
                </li>
                <li>
                  In all our design alternatives, we lack the call-to-action. A call-to-action for people to register
                  as donors would be effective in expressing our message about helping organ transplantation.
                </li>

                <li>
                  Our four design alternatives all focus on different aspects of this topic, thus confusing the audience
                  with different messages. We need to find a way to combine all those different aspects to single
                  visualization that conveys one cohesive message.
                </li>
              </ul>
            </MainText1>
          </Col>
        </Row>
      </StyledContainer>
    )
  }
}

export default IdeationFeedbackInfoVis