import React from 'react'
import {Row, Col, Container, Button, Modal, ModalBody, ModalHeader} from 'reactstrap'

import styled from 'styled-components'

import {Header2, getColOf3, getSeperatorWithHeight, HeaderForSubtitle, ZoomableImage} from '../text_style'
import designAlternative1Image from '../../images/infovis/design_alternative_1.jpg'
import { StyledContainer } from '../layout'
import {MainText1,Header3} from './infovis_style'

class DesignAlternative1InfoVis extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  click = () =>{
    console.log("Click")
  }


  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={4}>
            <Header3>
              Design Alternative 1
            </Header3>

            {getSeperatorWithHeight(28)}

            <MainText1>
              This  “Center Comparison” visualizes the factors for patients what they need for hospitals
              consideration. In this visualization, the audience stays in the middle. Each circle represents
              one transplant center. Using GPS location, centers which are geographically near to the person
              will be placed close to the patient icon while circles for those centers far off from him geographically
              are at the skirt. The circle size stands for the waiting list length. The scatterplot below
              compares the survival rate and death rate for all centers.
            </MainText1>
          </Col>

          <Col md={3}>
            <ZoomableImage src={designAlternative1Image} alt="Design alternative 1" className="img-fluid" onClick={this.toggle}/>

            <Modal isOpen={this.state.modal} size='lg'>
              <ModalHeader toggle={this.toggle}>User Journey Map</ModalHeader>
              <ModalBody>
                <Container>
                  <img src={designAlternative1Image} className={"img"} alt="User Journey Map"/>
                </Container>
              </ModalBody>

            </Modal>
          </Col>
        </Row>

      </StyledContainer>
    )
  }
}

export default DesignAlternative1InfoVis