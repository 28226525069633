import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight} from '../text_style'
import researchCroppedImage from '../../images/hci_foundation/Research-cropped.jpg'
import { StyledContainer } from '../layout'
import {Header2WithLeftBoarder, MainText1, Header2SubtitleWithLeftBorder } from './infovis_style'

class IdeationIntroInfoVis extends React.Component {


  render(){
    return(

      <StyledContainer fluid={true} >
        <Row>
          {getColOf3()}
          <Col md={6}> <Header2WithLeftBoarder> Ideation </Header2WithLeftBoarder> </Col>
        </Row>

        <Row>
          {getColOf3()}
          <Col md={6}> <Header2SubtitleWithLeftBorder>Explore different presentations for data about organ transplantation </Header2SubtitleWithLeftBorder></Col>
        </Row>

        {getSeperatorWithHeight(32)}

        <Row>
          {getColOf3()}
          <Col md={6}>
            <MainText1>
              In ideation, we start with brainstorming. We sketched our ideas onto the paper,
              and then created a simple low fidelity prototype in Sketch. At last, we places four of them
              into one poster to collect feedback from others.
            </MainText1>
          </Col>
        </Row>

        {getSeperatorWithHeight(32)}

      </StyledContainer>

    )
  }
}

export default IdeationIntroInfoVis