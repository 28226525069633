import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {Header2, MainText1, getColOf3, getSeperatorWithHeight, HeaderForSubtitle} from '../text_style'
import finalProductImage from '../../images/infovis/Developed_system_2.svg'
import { StyledContainer } from '../layout'
import {Header3} from './infovis_style'

class FinalProductInfoVis extends React.Component{
  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header3>
              Final Product
            </Header3>

            {getSeperatorWithHeight(16)}
          </Col>
        </Row>

        <Row>
          <Col md={3}/>
          <Col md={6}>
            <img src={finalProductImage} alt={"Final product"} className="img-fluid"/>
          </Col>
        </Row>
      </StyledContainer>
    )
  }
}

export default FinalProductInfoVis