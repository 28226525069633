import React from 'react'
import {Row,
  Col,
  Container,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption } from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight} from '../text_style'
import { StyledContainer } from '../layout'
import insight1 from '../../images/infovis/Insight_1.svg'
import insight2 from '../../images/infovis/Insight_2.svg'
import insight3 from '../../images/infovis/Insight_3.svg'
import insight4 from '../../images/infovis/Insight_4.svg'
import {Header3, MainText1} from './infovis_style'

const items = [
  {
    src: insight1
  },
  {
    src: insight2
  },
  {
    src: insight3
  },
  {
    src: insight4
  }
];


class LiteratureReviewInfoVis extends React.Component{


  constructor(props){
    super(props);
    this.state = { activeIndex: 0 };
  }

  onExiting = () => {
    this.animating = true;
  }

  onExited = () => {
    this.animating = false;
  }

  next = () => {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous = () => {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render(){
    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img src={item.src}/>
          <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
        </CarouselItem>
      );
    });

    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header3>
              Literature Review
            </Header3>

            {getSeperatorWithHeight(28)}
            <MainText1>
              Without any prior knowledge in organ donation in our team, we decided to read articles to
              familiarize ourselves with essential terms related to organ donation, and the situation in
              U.S. Our sources include news articles, online articles, videos, patients’ handbook, annual
              reports, and also others’ info graphic.  As a result, we understand the procedure of retrieving
              an organ, the matching procedure for patients’ waiting list, and the network of organ
              transplantation in United States.
            </MainText1>

            {getSeperatorWithHeight(28)}
            <MainText1>
              Our insights:
            </MainText1>
          </Col>
        </Row>

        {getSeperatorWithHeight(12)}
        <Row>
          <Col md={3}/>
          <Col md={6}>
            <Carousel
              activeIndex={this.state.activeIndex}
              next={this.next}
              previous={this.previous}
            >
              <CarouselIndicators items={items} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} />
              {slides}
              <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} style={{backgroundColor: "red"}}
                               />
              <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
            </Carousel>
          </Col>

        </Row>
      </StyledContainer>
    )
  }
}

export default LiteratureReviewInfoVis