import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import Youtubte from 'react-youtube'

import styled from 'styled-components'

import {Header2, getColOf3, getSeperatorWithHeight, HeaderForSubtitle} from '../text_style'
// import researchCroppedImage from '../../images/Research-cropped.jpg'
import { StyledContainer } from '../layout'
import {Header3, MainText1} from './infovis_style'

class InteractivePrototypeInfovis extends React.Component{
  render(){
    return(

      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header3>
              Interactive Prototype
            </Header3>

            {getSeperatorWithHeight(28)}
            <MainText1>
              We used the Principle to create an interactive prototype.
            </MainText1>

            {getSeperatorWithHeight(12)}
          </Col>
        </Row>

        <Row>
          {getColOf3()}
          <Col md={6} className="d-flex justify-content-center">
            <Youtubte videoId={"m50OXAaM18k"} />
          </Col>

        </Row>


      </StyledContainer>
    )
  }
}

export default InteractivePrototypeInfovis