import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight,

  HeaderForSubtitle} from '../text_style'
import researchCroppedImage from '../../images/hci_foundation/Research-cropped.jpg'
import { StyledContainer } from '../layout'
import {Header2WithLeftBoarder, MainText1, Header2SubtitleWithLeftBorder} from './infovis_style'

class PrototypeIntroInfoVis extends React.Component {


  render(){
    return(

      <StyledContainer fluid={true} >
        <Row>
          {getColOf3()}
          <Col md={6}> <Header2WithLeftBoarder> Prototype </Header2WithLeftBoarder> </Col>
        </Row>

        <Row>
          {getColOf3()}
          <Col md={6}> <Header2SubtitleWithLeftBorder>
            Decide on what our final will look like
          </Header2SubtitleWithLeftBorder></Col>
        </Row>

        {getSeperatorWithHeight(32)}

        <Row>
          {getColOf3()}
          <Col md={6}>
            <MainText1>
              After the feedback, we sat down in multiple meetings and tried to figure out the final design.
              We analyzed our feedback, cleaned up our data, and then decide on our design. To communicate our
              idea, gather others’ feedback, and further guide our development process, we produced prototypes
              through Sketch and coding.
            </MainText1>
          </Col>
        </Row>

      </StyledContainer>

    )
  }
}

export default PrototypeIntroInfoVis